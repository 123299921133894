import { gql, useQuery } from "@apollo/client";
import { Alert, PageHeader, Result } from "antd";
import { useAuth } from "auth";
import { contains } from "common/common";
import CleaningDayStatisticsBadge from "cleaning-new/components/cleaning-day-statistics-badge/CleaningDayStatisticsBadge";
import UserGroupsAvailabilityCalendar from "users/components/user-group-availability-calendar/UserGroupAvailabilityCalendar";

const QUERY = gql`
    query GetOrganizationCleaningGroups {
        organization(organizationId: "self") {
            id
            cleaningUserGroups {
                id
            }
        }
    }
`;

export default function DisplayCleaningAvailabilityView() {
    const { data, loading, error } = useQuery(QUERY);

    const { permissions } = useAuth();

    if (permissions && !contains(permissions, ['user:get:self', 'user_group:get', 'user_availability:get:self'])) {
        return (
            <Result
                status="error"
                title="No permissions"
                subTitle="You don't have permissions to view cleaners availability"
            />
        );
    }

    return (
        <PageHeader
            title="Cleaners availability"
        >
            {error && (
                <Alert
                    type="error"
                    showIcon
                    message="Failed to load cleaning user groups"
                />
            )}
            {!loading && data && (
                <UserGroupsAvailabilityCalendar
                    userGroupIds={data.organization.cleaningUserGroups.map(userGroup => userGroup.id)}
                    columnHeaderHeight={85}
                    renderDateHeaderExtra={date => (
                        <CleaningDayStatisticsBadge
                            date={date}
                        />
                    )}
                />
            )}
        </PageHeader>
    );
}