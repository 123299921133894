import { gql, useQuery } from "@apollo/client";
import { Alert, Card, Result, Table } from "antd";
import { compareAsc, parseISO } from "date-fns";

function columnDefinitions(name, type, index) {
    if (type === 'integer') {
        return {
            title: name,
            align: 'right',
            sorter: {
                compare: (a, b) => parseInt(a.values[index]) - parseInt(b.values[index]),
                multiple: index,
            },
            render: row => row.values[index],
        };
    }
    if (type === 'float') {
        return {
            title: name,
            align: 'right',
            sorter: {
                compare: (a, b) => parseFloat(a.values[index]) - parseFloat(b.values[index]),
                multiple: index,
            },
            render: row => row.values[index],
        };
    }
    if (type === 'boolean') {
        return {
            title: name,
            render: row => row.values[index] ? 'True' : 'False',
        };
    }
    if (type === 'string') {
        return {
            title: name,
            sorter: {
                compare: (a, b) => a.values[index].localeCompare(b.values[index]),
                multiple: index,
            },
            render: row => row.values[index],
        };
    }
    if (type === 'date') {
        return {
            title: name,
            sorter: {
                compare: (a, b) => compareAsc(parseISO(a.values[index]), parseISO(b.values[index])),
                multiple: index,
            },
            render: row => row.values[index],
        };
    }
    if (type === 'datetime') {
        return {
            title: name,
            sorger: {
                compare: (a, b) => compareAsc(parseISO(a.values[index]), parseISO(b.values[index])),
                multiple: index,
            },
            render: row => row.values[index],
        };
    }
    if (type === 'interval') {
        return {
            title: name,
            render: row => row.values[index],
        };
    }
    if (type === 'array') {
        return {
            title: name,
            render: row => row.values[index],
        };
    }
}


const QUERY = gql`
    query GetStayqlResults($input: StayqlQuery!) {
        stayql(input: $input) {
            columns {
                name
                type
            }
            rows {
                values
            }
            error {
                message
            }
        }
    }
`;


export default function StayqlResultsTable(props) {
    const {
        query,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            input: {
                query,
            },
        },
        fetchPolicy: 'no-cache',
        skip: !query,
    });

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to get statistics"
            />
        );
    }

    if (loading) {
        return (
            <Card loading />
        );
    }

    if (!data) {
        return (
            <Result
                status="info"
                title="No query"
                subTitle="Please specify query in order to show results"
            />
        );
    }

    if (data.stayql.error) {
        return (
            <Alert
                type="error"
                showIcon
                message={data.stayql.error.message}
            />
        );
    }

    const tableColumns = data.stayql.columns
        .map((column, index) => columnDefinitions(column.name, column.type, index));

    return (
        <Table
            dataSource={
                [...data.stayql.rows ?? []]
                    .map((row, index) => ({ key: index, ...row }))
            }
            columns={tableColumns}
            pagination={false}
            size="small"
            rowKey="key"
        />
    );
}