import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import Card from "antd/lib/card/Card";
import { blue } from "@ant-design/colors";
import { addDays, differenceInDays, formatISO, isEqual } from "date-fns";
import styles from "components/Calendar.module.css";


const DEFAULT_DAYS_WIDTH = 20;
const OVERSCAN_CELLS = 5;


const Cell = memo(({ date, row, isSelected, cellStyle, selectedCellColor, renderCell, handleCellClick }) => (
    <div
        className={styles.calendarCell}
        style={{
            ...cellStyle,
            backgroundColor: isSelected ? (selectedCellColor || blue[1]) : '',
        }}
        onClick={() => handleCellClick(row, date)}
    >
        {renderCell(row, date, isSelected)}
    </div>
), (prevState, nextState) => {
    return (
        isEqual(prevState.date, nextState.date) &&
        Object.is(prevState.row, nextState.row) &&
        prevState.isSelected === nextState.isSelected &&
        Object.is(prevState.cellStyle, nextState.cellStyle) &&
        Object.is(prevState.selectedCellColor, nextState.selectedCellColor) &&
        Object.is(prevState.renderCell, nextState.renderCell) &&
        Object.is(prevState.handleCellClick, nextState.handleCellClick)
    );
});


export default function Calendar(props) {
    const {
        dataSource,
        loading,
        dateFrom,
        dateTo,
        rowKey,
        header,
        renderRowHeaderCell,
        renderColumnHeaderCell,
        renderCell,
        scrollToDate,
        columnHeaderHeight,
        rowHeaderWidth,
        cellHeight,
        cellWidth,
        selectedCells,
        selectedCellColor,
        disabledCells,
        autofocus,
        onRowHeaderClick,
        onColumnHeaderClick,
        onCellClick,
        onSelect,
        onScrollDate,
    } = props;

    const mainContainer = useRef();
    const scrollToDateDone = useRef(false);
    const autofocusDone = useRef(false);

    const [mainContainerWidth, setMainContainerWidth] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        scrollToDateDone.current = false;
    }, [scrollToDate]);

    useLayoutEffect(() => {
        setMainContainerWidth(mainContainer?.current?.childRef?.current?.getBoundingClientRect()?.width);
    }, []);

    const dates = useMemo(() =>
        [...Array(differenceInDays(dateTo, dateFrom) + 1).keys()]
            .map(i => addDays(dateFrom, i)),
        [dateFrom, dateTo]);

    const currentDateIndex = Math.floor(scrollLeft / cellWidth);
    const daysWidth = mainContainerWidth
        ? Math.ceil(mainContainerWidth / cellWidth)
        : DEFAULT_DAYS_WIDTH;
    const renderFromDateIndex = Math.max(currentDateIndex - OVERSCAN_CELLS, 0);
    const renderToDateIndex = Math.min(currentDateIndex + daysWidth + OVERSCAN_CELLS, dates.length);
    const cellPlaceholderWidth = renderFromDateIndex * cellWidth;

    const datesToRender = dates.slice(renderFromDateIndex, renderToDateIndex);

    const leftPaneStyle = useMemo(() => ({
        width: `${rowHeaderWidth}px`,
    }), [rowHeaderWidth]);

    const rightPaneStyle = useMemo(() => ({
        width: `calc(100% - ${rowHeaderWidth}px)`,
    }), [rowHeaderWidth]);

    const calendarRowHeaderContainerStyle = useMemo(() => ({
        height: `calc(100% - ${columnHeaderHeight}px)`,
    }), [columnHeaderHeight]);

    const calendarCellContainerStyle = useMemo(() => ({
        height: `calc(100% - ${columnHeaderHeight}px)`,
    }), [columnHeaderHeight]);

    const cornerStyle = useMemo(() => ({
        width: `${rowHeaderWidth}px`,
        minWidth: `${rowHeaderWidth}px`,
        height: `${columnHeaderHeight}px`,
        minHeight: `${columnHeaderHeight}px`,
    }), [rowHeaderWidth, columnHeaderHeight]);

    const rowHeaderCellStyle = useMemo(() => ({
        width: `${rowHeaderWidth}px`,
        minWidth: `${rowHeaderWidth}px`,
        height: `${cellHeight}px`,
        minHeight: `${cellHeight}px`,
    }), [rowHeaderWidth, cellHeight]);

    const columnHeaderCellStyle = useMemo(() => ({
        width: `${cellWidth}px`,
        minWidth: `${cellWidth}px`,
        height: `${columnHeaderHeight}px`,
        minHeight: `${columnHeaderHeight}px`,
    }), [cellWidth, columnHeaderHeight]);

    const cellRowStyle = useMemo(() => ({
        width: `${(differenceInDays(dateTo, dateFrom) + 1) * cellWidth}px`,
    }), [dateFrom, dateTo, cellWidth]);

    const cellPlaceholderStyle = useMemo(() => ({
        height: `${cellHeight}px`,
        width: `${cellPlaceholderWidth}px`,
    }), [cellHeight, cellPlaceholderWidth]);

    const cellStyle = useMemo(() => ({
        width: `${cellWidth}px`,
        minWidth: `${cellWidth}px`,
        height: `${cellHeight}px`,
    }), [cellWidth, cellHeight]);

    function handleRowHeaderClick(row) {
        if (onRowHeaderClick) {
            onRowHeaderClick(row);
        }
    }

    function handleColumnHeaderClick(date) {
        if (onColumnHeaderClick) {
            onColumnHeaderClick(date);
        }
    }

    const handleCellClick = useCallback((row, date) => {
        const isCellDisabled = [...disabledCells ?? []].find(([rowId, otherDate]) => rowId === row[rowKey] && isEqual(date, otherDate));

        if (!isCellDisabled) {
            if (onSelect) {
                onSelect([row[rowKey], date]);
            }
        }

        if (onCellClick) {
            onCellClick(row[rowKey], date);
        }
    }, [disabledCells, rowKey, onCellClick, onSelect]);

    function isCellSelected(row, date) {
        return selectedCells.find(([r, d]) => r === row[rowKey] && isEqual(d, date));
    }

    function isColumnSelected(date) {
        if (!dataSource || dataSource.length === 0) {
            return false;
        }

        const allRowIds = [...dataSource ?? []].map(row => row[rowKey]);
        return allRowIds.every(rowId => selectedCells.find(([r, d]) => r === rowId && isEqual(d, date)));
    }

    function handleScroll(el) {
        if (el !== mainContainer?.current?.childRef?.current) {
            return;
        }
        if (Math.abs(el.scrollLeft - scrollLeft) > cellWidth) {
            setScrollLeft(el.scrollLeft);
        }
        if (onScrollDate && scrollToDateDone.current) {
            const dayLeft = addDays(dateFrom, Math.floor(el.scrollLeft / cellWidth));
            const dayRight = addDays(dayLeft, daysWidth);
            onScrollDate([dayLeft, dayRight]);
        }
    }

    function handleScrollToDate(el) {
        if (scrollToDate && !scrollToDateDone.current && el?.childRef?.current) {
            el.childRef.current.scrollLeft = differenceInDays(scrollToDate, dateFrom) * cellWidth;
            scrollToDateDone.current = true;
        }
    }

    function handleAutofocus(el) {
        if (autofocus && !autofocusDone.current && el?.childRef?.current) {
            el.childRef.current.focus();
            autofocusDone.current = true;
        }
    }

    return (
        <div className={styles.container}>
            {header && (
                <div className={styles.calendarControlsContainer}>
                    {header}
                </div>
            )}
            {loading && (
                <div className={styles.calendarLoadingContainer}>
                    <Card loading />
                </div>
            )}
            {!loading && dataSource && (
                <ScrollSync
                    onSync={el => handleScroll(el)}
                >
                    <div className={styles.calendarContainer}>
                        <div
                            className={styles.leftPane}
                            style={leftPaneStyle}
                        >
                            <div
                                className={styles.calendarCorner}
                                style={cornerStyle}
                            />
                            <ScrollSyncPane group="vertical">
                                <div
                                    className={styles.calendarRowHeaderContainer}
                                    style={calendarRowHeaderContainerStyle}
                                >
                                    <div className={styles.calendarRowHeaderScrollable}>
                                        {dataSource.map(row => (
                                            <div
                                                key={row[rowKey]}
                                                className={styles.calendarRowHeaderCell}
                                                style={rowHeaderCellStyle}
                                                onClick={() => handleRowHeaderClick(row)}
                                            >
                                                {renderRowHeaderCell(row)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </ScrollSyncPane>
                        </div>
                        <div
                            className={styles.rightPane}
                            style={rightPaneStyle}
                        >
                            <ScrollSyncPane group="horizontal">
                                <div className={styles.calendarColumnHeaderContainer}>
                                    <div className={styles.calendarColumnHeaderScrollable}>
                                        {dates.map(date => (
                                            <div
                                                key={formatISO(date, { representation: 'date' })}
                                                className={styles.calendarColumnHeaderCell}
                                                style={{
                                                    ...columnHeaderCellStyle,
                                                    backgroundColor: isColumnSelected(date) ? (selectedCellColor || blue[1]) : '',
                                                }}
                                                onClick={() => handleColumnHeaderClick(date)}
                                            >
                                                {renderColumnHeaderCell(date)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </ScrollSyncPane>
                            <ScrollSyncPane
                                group={["horizontal", "vertical"]}
                                ref={el => {
                                    mainContainer.current = el;
                                    handleScrollToDate(el);
                                    handleAutofocus(el);
                                }}
                            >
                                <div
                                    className={styles.calendarCellContainer}
                                    style={calendarCellContainerStyle}
                                    tabIndex={0}
                                >
                                    {dataSource.map(row => (
                                        <div
                                            key={row[rowKey]}
                                            className={styles.calendarCellRow}
                                            style={cellRowStyle}
                                        >
                                            <div style={cellPlaceholderStyle} />
                                            {datesToRender.map(date => (
                                                <Cell
                                                    date={date}
                                                    row={row}
                                                    isSelected={isCellSelected(row, date)}
                                                    cellStyle={cellStyle}
                                                    selectedCellColor={selectedCellColor}
                                                    renderCell={renderCell}
                                                    handleCellClick={handleCellClick}
                                                    key={formatISO(date, { representation: 'date' })}
                                                />
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </ScrollSyncPane>
                        </div>
                    </div>
                </ScrollSync>
            )}
        </div>
    );
}