import { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, Col, Drawer, Form, message, Modal, Result, Row, Skeleton, Tooltip } from "antd";
import { ClockCircleOutlined, DownOutlined, SaveOutlined } from "@ant-design/icons";
import { addDays, formatISO, isBefore, isEqual, startOfToday } from "date-fns";
import { toggleSelect } from "common/common";
import Calendar from "components/Calendar";
import DatePicker from "components/DatePicker";
import UserAvailabilityCommentForm from "users/forms/UserAvailabilityCommentForm";
import UserAvailabilityDayDetails from "users/components/user-availability-day-details/UserAvailabilityDayDetails";
import RowHeader from "./RowHeader";
import ColumnHeader from "./ColumnHeader";
import Cell from "./Cell";
import ContextMenu from "./ContextMenu";
import "./style.css";
import { useAuth } from "auth";

const AVAILABILITY_QUERY = gql`
    query GetAvailability($userGroupIds: [ID]!, $dateFrom: Date!, $dateTo: Date!) {
        users(filter: {userGroupIds: $userGroupIds}) {
            id
            firstName
            lastName
            availabilityNote
            availability(filter: {dateFrom: $dateFrom, dateTo: $dateTo}) {
                id
                date
                availability
                comment
            }
        }
    }
`;

const SET_AVAILABILITY_MUTATION = gql`
    mutation SetAvailability($input: SetUserAvailabilityInput!) {
        setUserAvailability(input: $input) {
            error {
                type
                message
            }
            userAvailability {
                id
                date
                availability
                comment
            }
        }
    }
`;

export default function UserGroupsAvailabilityCalendar(props) {
    const {
        columnHeaderHeight,
        rowHeaderWidth,
        cellHeight,
        cellWidth,
        userGroupIds,
        renderDateHeaderExtra,
        renderUserHeaderExtra,
    } = props;

    const { user, permissions } = useAuth();

    const [dateRange, setDateRange] = useState([startOfToday(), addDays(startOfToday(), 28)]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [commentModalOpen, setCommentModalOpen] = useState(false);
    const [commentForm] = Form.useForm();
    const [overwriteCommentWarning, setOverwriteCommentWarning] = useState(false);

    const { data: availabilityData, loading: availabilityLoading, error: availabilityError } = useQuery(AVAILABILITY_QUERY, {
        variables: {
            userGroupIds,
            dateFrom: dateRange?.[0],
            dateTo: dateRange?.[1],
        },
        skip: !dateRange,
    });
    const [setAvailability, { loading: setAvailabilityLoading }] = useMutation(SET_AVAILABILITY_MUTATION, {
        update(cache) {
            cache.evict({
                fieldName: 'users',
                id: 'ROOT_QUERY',
            })
        },
    });

    if (availabilityLoading) {
        return (
            <Skeleton />
        );
    }

    if (availabilityError) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load user availabilities"
            />
        );
    }

    if (availabilityData.users.length === 0) {
        return (
            <Card>
                <Result
                    status="info"
                    title="No users"
                    subTitle="There are no users in this group. Please select different group"
                />
            </Card>
        );
    }

    function handleSelect(value) {
        const newSelectedDays = toggleSelect(selectedDays, value, (a, b) => a[0] === b[0] && isEqual(a[1], b[1]))
            .filter(([userId, _]) => canViewUserAvailability(userId));
        setSelectedDays(newSelectedDays);

        if (newSelectedDays.length === 0) {
            setDrawerOpen(false);
        }
    }

    function handleSelectColumn(columnDate) {
        const allSelected = [...availabilityData.users ?? []]
            .filter(user => canViewUserAvailability(user.id))
            .every(user => selectedDays.find(([userId, date]) => userId === user.id && isEqual(date, columnDate)));

        if (allSelected) {
            const newSelectedDays = [...selectedDays].filter(([_, date]) => !isEqual(date, columnDate))
                .filter(([userId, _]) => canViewUserAvailability(userId));

            setSelectedDays(newSelectedDays);
        }
        else {
            const newSelectedDays = [...selectedDays, ...[...availabilityData.users ?? []].map(user => ([user.id, columnDate]))]
                .filter((value, index, array) => array.findIndex(otherValue => otherValue[0] === value[0] && isEqual(otherValue[1], value[1])) === index)
                .filter(([userId, _]) => canViewUserAvailability(userId));

            setSelectedDays(newSelectedDays);
        }
    }

    function handleDeselectAll() {
        setSelectedDays([]);
    }

    function handleAction(action) {
        if (action[0] === 'setAvailability') {
            const availability = action[1];
            handleSetAvailability(availability);
        }
        if (action[0] === 'clearAvailability') {
            handleClearAvailability();
        }
        if (action[0] === 'addComment') {
            handleAddComment();
        }
        if (action[0] === 'clearComment') {
            handleClearComment();
        }
    }

    function handleSetAvailability(availability) {
        selectedDays.forEach(([userId, date]) => {
            setAvailability({
                variables: {
                    input: {
                        date,
                        userId,
                        availability,
                    },
                },
            })
                .then(response => {
                    if (response.data.setUserAvailability.error) {
                        message.error("Failed to set availability");
                    }
                    else {
                        message.success("Availability set");
                    }
                });
        });
    }

    function handleClearAvailability() {
        const haveComment = selectedDays.some(([userId, date]) => {
            return availabilityData.users
                .find(user => user.id === userId)
                ?.availability
                ?.find(item => isEqual(item.date, date))
                ?.comment;
        });

        selectedDays.forEach(([userId, date]) => {
            setAvailability({
                variables: {
                    input: {
                        date,
                        userId,
                        availability: null,
                        comment: null,
                    },
                },
            })
                .then(response => {
                    if (response.data.setUserAvailability.error) {
                        message.error("Failed to clear availability");
                    }
                    else {
                        message.success("Availability cleared");
                        if (haveComment) {
                            message.success("Comments cleared");
                        }
                    }
                });
        })
    }

    function handleAddComment() {
        setOverwriteCommentWarning(false);

        const allComments = selectedDays.map(([userId, date]) => {
            return availabilityData.users
                .find(user => user.id === userId)
                ?.availability
                ?.find(item => isEqual(item.date, date))
                ?.comment;
        });

        const allCommentsTheSame = allComments.length > 0 && allComments.every(comment => comment) && allComments.every(comment => comment === allComments[0]);
        const someHaveComments = allComments.length > 0 && allComments.some(comment => comment);

        if (allCommentsTheSame) {
            commentForm.setFieldValue('comment', allComments[0]);
        }

        if (someHaveComments) {
            setOverwriteCommentWarning(true);
        }

        setCommentModalOpen(true);
    }

    function handleClearComment() {
        selectedDays.forEach(([userId, date]) => {
            const currentAvailability = availabilityData.users
                .find(user => user.id === userId)?.availability
                ?.find(item => isEqual(item.date, date));

            if (currentAvailability) {
                setAvailability({
                    variables: {
                        input: {
                            date,
                            userId,
                            availability: currentAvailability.availability,
                            comment: null,
                        },
                    },
                })
                    .then(response => {
                        if (response.data.setUserAvailability.error) {
                            message.error("Failed to clear comment");
                        }
                        else {
                            message.success("Comment cleared");
                        }
                    });
            }
        });
    }

    function handleSubmitComment() {
        commentForm
            .validateFields()
            .then(values => {
                return Promise.all(
                    selectedDays.map(([userId, date]) => {
                        const currentAvailability = availabilityData.users
                            .find(user => user.id === userId)
                            ?.availability
                            ?.find(item => isEqual(item.date, date));

                        if (currentAvailability) {
                            return setAvailability({
                                variables: {
                                    input: {
                                        date,
                                        userId,
                                        availability: currentAvailability.availability,
                                        comment: values.comment,
                                    },
                                },
                            })
                                .then(response => {
                                    if (response.data.setUserAvailability.error) {
                                        return false;
                                    }
                                    else {
                                        return true;
                                    }
                                })
                        }
                        else {
                            return Promise.resolve();
                        }
                    })
                );
            })
            .then(success => {
                if (success) {
                    message.success("Comment added");
                }
                else {
                    message.error("Failed to add comment");
                }

                setCommentModalOpen(false);
            });
    }

    function allSelectedDaysHaveAvailability() {
        return selectedDays.every(([userId, date]) => {
            const user = [...availabilityData.users ?? []]
                .find(user => user.id === userId);

            const availabiltiy = [...user?.availability ?? []]
                .find(item => isEqual(item.date, date))
                ?.availability;

            return !availabiltiy;
        });
    }

    function noneSelectedDaysHaveComments() {
        return selectedDays.every(([userId, date]) => {
            const user = [...availabilityData.users ?? []]
                .find(user => user.id === userId);

            const comment = [...user?.availability ?? []]
                .find(item => isEqual(item.date, date))
                ?.comment;

            return !comment;
        });
    }

    function hasSelectedDays() {
        if (!selectedDays || selectedDays.length === 0) {
            return false;
        }

        return true;
    }

    function hasViewHistoryPermissions() {
        if (!user) {
            return false;
        }

        const allSelectedAreSelf = selectedDays.every(([userId, _]) => userId === user.id);
        if (allSelectedAreSelf && !permissions.includes('user_availability:get:self')) {
            return false;
        }
        if (!allSelectedAreSelf && !permissions.includes('user_availability:get:other')) {
            return false;
        }

        return true;
    }

    function hasEditCellPermissions() {
        if (!user) {
            return false;
        }

        const allSelectedAreSelf = selectedDays.every(([userId, _]) => userId === user.id);

        if (allSelectedAreSelf && !permissions.includes('user_availability:set:self')) {
            return false;
        }
        if (!allSelectedAreSelf && !permissions.includes('user_availability:set:other')) {
            return false;
        }

        return true;
    }

    function hasSelectedDaysInPast() {
        if (!hasSelectedDays()) {
            return false;
        }

        const someSelectedInPast = selectedDays.some(([_, date]) => isBefore(date, startOfToday()));
        if (someSelectedInPast) {
            return true;
        }

        return false;
    }

    function canViewHistorySelected() {
        if (!hasSelectedDays()) {
            return false;
        }

        if (!hasViewHistoryPermissions()) {
            return false;
        }

        return true;
    }

    function getHistoryDisabledReason() {
        if (!hasSelectedDays()) {
            return "Select days in order to view history";
        }
        if (!hasViewHistoryPermissions()) {
            return "You don't have permissions to view history of selected days";
        }
    }

    function canEditSelected() {
        if (!hasSelectedDays()) {
            return false;
        }

        if (!hasEditCellPermissions()) {
            return false;
        }

        if (hasSelectedDaysInPast()) {
            return false;
        }

        return true;
    }

    function getEditDisabledReason() {
        if (!hasSelectedDays()) {
            return "Select days in order to edit";
        }
        if (!hasEditCellPermissions()) {
            return "You don't have permissions to edit selected cells";
        }
        if (hasSelectedDaysInPast()) {
            return "Some of selected days are in past";
        }
    }

    function canViewUserAvailability(userId) {
        if (user && userId === user.id && permissions.includes('user_availability:get:self')) {
            return true;
        }
        if (user && userId !== user.id && permissions.includes('user_availability:get:other')) {
            return true;
        }
        return false;
    }

    return (
        <div className="availability-calendar-container">
            <div className="availability-calendar-controls-container">
                {hasSelectedDays()
                    ? (
                        <Button
                            onClick={() => handleDeselectAll()}
                        >
                            Deselect all
                        </Button>
                    )
                    : (
                        <Button disabled>
                            Deselect all
                        </Button>
                    )
                }
                {canViewHistorySelected()
                    ? (
                        <Button
                            icon={<ClockCircleOutlined />}
                            onClick={() => setDrawerOpen(!drawerOpen)}
                        >
                            History
                        </Button>
                    )
                    : (
                        <Tooltip title={getHistoryDisabledReason()}>
                            <Button
                                icon={<ClockCircleOutlined />}
                                disabled
                            >
                                History
                            </Button>
                        </Tooltip>
                    )
                }
                <ContextMenu
                    selectedDays={selectedDays}
                    clearAvailabilityDisabled={allSelectedDaysHaveAvailability()}
                    addCommentDisabled={allSelectedDaysHaveAvailability()}
                    clearCommentDisabled={noneSelectedDaysHaveComments()}
                    disabled={!canEditSelected()}
                    trigger={["click"]}
                    onAction={action => handleAction(action)}
                >
                    {canEditSelected()
                        ? (
                            <Button
                                icon={<DownOutlined />}
                            >
                                Edit
                            </Button>
                        )
                        : (
                            <Tooltip title={getEditDisabledReason()}>
                                <Button
                                    icon={<DownOutlined />}
                                    disabled
                                >
                                    Edit
                                </Button>
                            </Tooltip>
                        )
                    }
                </ContextMenu>
                <DatePicker.RangePicker
                    value={dateRange}
                    onChange={value => setDateRange(value)}
                />
            </div>
            <div className="availability-calendar-content-container">
                <Calendar
                    dataSource={
                        [...availabilityData?.users ?? []]
                            .sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))
                    }
                    dateFrom={dateRange[0]}
                    dateTo={dateRange[1]}
                    selectedCells={selectedDays}
                    onSelect={value => handleSelect(value)}
                    onColumnHeaderClick={date => handleSelectColumn(date)}
                    rowKey="id"
                    renderRowHeaderCell={user => (
                        <RowHeader
                            user={user}
                            renderExtra={renderUserHeaderExtra}
                        />
                    )}
                    renderColumnHeaderCell={date => (
                        <ColumnHeader
                            date={date}
                            renderExtra={renderDateHeaderExtra}
                        />
                    )}
                    renderCell={(user, date, selected) => (
                        <Cell
                            user={user}
                            date={date}
                            loading={selected && (setAvailabilityLoading)}
                            disabled={!canViewUserAvailability(user.id)}
                        />
                    )}
                    columnHeaderHeight={columnHeaderHeight ?? 50}
                    rowHeaderWidth={rowHeaderWidth ?? 200}
                    cellHeight={cellHeight ?? 40}
                    cellWidth={cellWidth ?? 40}
                />
            </div>
            <Drawer
                open={drawerOpen}
                title="Details"
                onClose={() => setDrawerOpen(false)}
                closable
                width={300}
                mask={false}
            >
                {selectedDays.map(([userId, date]) => (
                    <UserAvailabilityDayDetails
                        userId={userId}
                        date={date}
                        onClose={() => handleSelect([userId, date])}
                        key={`${userId}-${formatISO(date, { representation: 'date' })}`}
                    />
                ))}
            </Drawer>
            <Modal
                open={commentModalOpen}
                title="Comment"
                okText="Save"
                onOk={() => handleSubmitComment()}
                onCancel={() => setCommentModalOpen(false)}
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: setAvailabilityLoading,
                }}
                destroyOnClose
            >
                <Row gutter={[16, 16]}>
                    {overwriteCommentWarning && (
                        <Col span={24}>
                            <Alert
                                type="warning"
                                showIcon
                                message="Some of selected days already have comments. After saving, existing comments will be overwritten."
                            />
                        </Col>
                    )}
                    <Col span={24}>
                        <UserAvailabilityCommentForm
                            form={commentForm}
                            preserve={false}
                        />
                    </Col>
                </Row>
            </Modal>
        </div>
    );
}