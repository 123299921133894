import { Form } from "antd";
import CodeEditor from "stayql/components/code-editor/CodeEditor";

export default function QueryForm(props) {
    const {
        query,
        form,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            layout="vertical"
            {...otherProps}
        >
            <Form.Item
                name="query"
                noStyle
            >
                <CodeEditor />
            </Form.Item>
        </Form>
    )
}