import { PlusOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Col, Form, List, message, Modal, Row, Skeleton } from "antd";
import JobsFilterPresetForm from "jobs-new/forms/JobsFilterPresetForm";
import { useState } from "react";
import ListItem from "./ListItem";
import { useAuth } from "auth";

const QUERY = gql`
    query GetUserGroupJobsFilterPresets($userGroupId: ID!) {
        userGroup(userGroupId: $userGroupId) {
            id
            jobsFilterPresets {
                id
                name
                preset
            }
        }
    }
`;

const CREATE_MUTATION = gql`
    mutation CreateUserGroupJobsFilterPreset($input: CreateUserGroupJobsFilterPresetInput!) {
        createUserGroupJobsFilterPreset(input: $input) {
            userGroupJobsFilterPreset {
                id
                name
                preset
            }
            error {
                type
                message
            }
        }
    }
`;

export default function JobsFilterPresets(props) {
    const {
        userGroupId,
    } = props;

    const { userGroupIds, permissions } = useAuth();

    const canEdit = userGroupIds.includes(userGroupId)
        ? permissions.includes('user_group_jobs_filter_preset:set:self')
        : permissions.includes('user_group_jobs_filter_preset:set:other');

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            userGroupId,
        },
    });

    const [createJobsFilterPreset, { loading: createLoading }] = useMutation(CREATE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'UserGroup',
                    id: userGroupId,
                }),
                fieldName: 'jobsFilterPresets',
            });
        },
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load jobs filter presets"
            />
        );
    }

    function handleCreate() {
        form
            .validateFields()
            .then(values => {
                createJobsFilterPreset({
                    variables: {
                        input: {
                            userGroupId,
                            name: values.name,
                            preset: {
                                dateFrom: values.dateFrom,
                                dateTo: values.dateTo,
                                statuses: values.statuses,
                                tags: values.tags,
                                authors: values.authors,
                                createdByMe: values.createdByMe,
                                watchers: values.watchers,
                                watchedByMe: values.watchedByMe,
                                watchedByMyGroups: values.watchedByMyGroups,
                                assignerIds: values.assignerIds,
                                assignedToMe: values.assignedToMe,
                                locations: values.locations,
                            },
                        },
                    },
                })
                    .then(response => {
                        if (response.data.createUserGroupJobsFilterPreset.error) {
                            message.error("Failed creating jobs filter preset")
                        }
                        else {
                            message.success("Jobs filter preset created");
                        }
                        setModalOpen(false);
                    })
                    .catch(() => {
                        message.error("Network error");
                        setModalOpen(false);
                    })
            });
    }

    return (
        <List
            dataSource={
                [...data?.userGroup?.jobsFilterPresets ?? []]
                    .sort((a, b) => a.name.localeCompare(b.name))
            }
            renderItem={jobsFilterPreset => (
                <ListItem
                    userGroupJobsFilterPreset={jobsFilterPreset}
                    userGroupId={userGroupId}
                />
            )}
            footer={
                <Row justify="end">
                    <Col>
                        {canEdit && (
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => setModalOpen(true)}
                            >
                                Create jobs filter preset
                            </Button>
                        )}
                        <Modal
                            title="Create jobs filter preset"
                            width={800}
                            open={modalOpen}
                            destroyOnClose
                            onCancel={() => setModalOpen(false)}
                            onOk={() => handleCreate()}
                            okText="Create"
                            okButtonProps={{
                                icon: <PlusOutlined />,
                                loading: createLoading,
                            }}
                        >
                            <JobsFilterPresetForm
                                form={form}
                                labelCol={{
                                    span: 6,
                                }}
                                wrapperCol={{
                                    span: 18,
                                }}
                                preserve={false}
                            />
                        </Modal>
                    </Col>
                </Row>
            }
        />
    );
}