import { gql, useMutation } from "@apollo/client";
import { Button, Card, Col, Form, message, PageHeader, Result, Row } from "antd";
import { useAuth } from "auth";
import { contains } from "common/common";
import { formToJob } from "jobs-new/common";
import JobForm from "jobs-new/forms/JobForm";
import { useLocation, useNavigate } from "react-router-dom";

export const MUTATION = gql`
    mutation CreateJobNew($input: CreateJobNewInput!) {
        createJobNew(input: $input) {
            error {
                type
                message
            }
            jobNew {
                id
                status
                statusChanges {
                    id
                    status
                    updater {
                        id
                        firstName
                        lastName
                    }
                    updatedAt
                }
                author {
                    id
                    firstName
                    lastName
                }
                createdAt
                title
                description
                calculatedDate
                comments {
                    id
                    author {
                        id
                        firstName
                        lastName
                    }
                    createdAt
                    description
                    attachments {
                        id
                        fileName
                        originalFileName
                        mimeType
                        createdAt
                        getUrl
                    }
                }
                watchers {
                    id
                    firstName
                    lastName
                }
                watcherGroups {
                    id
                    name
                }
                attachments {
                    id
                    fileName
                    originalFileName
                    mimeType
                    createdAt
                    getUrl
                }
                relationsApartmentClosestAvailable {
                    id
                    apartment {
                        id
                        name
                    }
                    closestAvailableDate
                }
                relationsApartmentDate {
                    id
                    apartment {
                        id
                        name
                    }
                    date
                }
                relationsReservationStartDate {
                    id
                    reservation {
                        id
                        startDate
                    }
                }
                relationsReservationEndDate {
                    id
                    reservation {
                        id
                        endDate
                    }
                }
                relationsDate {
                    date
                }
            }
        }
    }
`;

export default function CreateJobView() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const { permissions } = useAuth();

    const [createJob, { loading }] = useMutation(MUTATION);

    const [form] = Form.useForm();

    function handleCreate() {
        form
            .validateFields()
            .then(values => {
                const input = formToJob(values);
                return createJob({
                    variables: {
                        input,
                    },
                })
            })
            .then(response => {
                if (response.data.createJobNew.error) {
                    message.error("Failed creating job");
                }
                else {
                    if (state?.nextUrl) {
                        navigate(state?.nextUrl, { replace: true });
                    }
                    else {
                        navigate(`/jobs-new/${response.data.createJobNew.jobNew.id}`, { replace: true });
                    }
                }
            })
            .catch(() => {
                message.error("Network error");
            })
    }

    if (!contains(permissions, ['user:get:self', 'user:get:other', 'user_group:get', 'apartment:get', 'storage:get', 'reservation:get', 'job:create'])) {
        return (
            <Result
                status="error"
                title="No permissions"
                subTitle="You don't have permissions to create jobs"
            />
        );
    }

    return (
        <PageHeader
            title="Create job"
            onBack={() => navigate(-1)}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <JobForm
                            form={form}
                            job={{
                                title: state?.title,
                                description: state?.description,
                                tags: state?.tags,
                                priority: state?.priority,
                                watchers: state?.watchers,
                                assignerIds: state?.assignerIds,
                                location: state?.location,
                                dateType: state?.dateType,
                                afterDate: state?.afterDate,
                                date: state?.date,
                                reservationId: state?.reservationId,
                                attachmentIds: state?.attachmentIds,
                            }}
                            hideTitle={state?.hideTitle}
                            hideDescription={state?.hideDescription}
                            hideTags={state?.hideTags}
                            hidePriority={state?.hidePriority}
                            hideWatchers={state?.hideWatchers}
                            hideAssigners={state?.hideAssigners}
                            hideLocation={state?.hideLocation}
                            hideDate={state?.hideDate}
                            hideAttachments={state?.hideAttachments}
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 12,
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => handleCreate()}
                                    loading={loading}
                                >
                                    Create
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    )
}