import { useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, Col, message, Row, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { addDays, max } from "date-fns";
import JobAttachmentsList from "jobs-new/components/JobAttachmentsList";
import JobStatusDropdown from "jobs-new/components/JobStatusDropdown";
import JobAssignersList from "jobs-new/components/job-assigners-list/JobAssignersList";
import JobDescription from "jobs-new/components/JobDescription";
import JobReservationSituation from "jobs-new/components/JobReservationSituation";
import JobWatchersList from "jobs-new/components/job-watchers-list/JobWatchersList";
import JobCardTitle from "./JobCardTitle";
import { useAuth } from "auth";

const QUERY = gql`
    query GetJobNewForCard($jobId: ID!) {
        jobNew(jobNewId: $jobId) {
            id
            status
            statusChanges {
                id
                status
                updater {
                    id
                    firstName
                    lastName
                }
                updatedAt
            }
            author {
                id
                firstName
                lastName
                phone
            }
            createdAt
            title
            description
            tags
            priority
            calculatedDate
            assigners {
                id
                firstName
                lastName
            }
            watchers {
                id
                firstName
                lastName
            }
            watcherGroups {
                id
                name
            }
            attachments {
                id
                fileName
                originalFileName
                mimeType
                createdAt
                getUrl
            }
            apartment {
                id
                name
            }
            storage {
                id
                name
            }
            startingReservation {
                id
                checkinTime
            }
            currentReservation {
                id
            }
            endingReservation {
                id
                checkoutTime
            }
            comments {
                id
                author {
                    id
                    firstName
                    lastName
                }
                createdAt
                description
                attachments {
                    id
                }
            }
            relationsApartmentClosestAvailable {
                id
                afterDate
            }
            deleted
        }
    }
`;

const UPDATE_WATCHERS_MUTATION = gql`
    mutation UpdateWatchers($input: UpdateJobNewInput!) {
        updateJobNew(input: $input) {
            error {
                type
                message
            }
            jobNew {
                id
                watchers {
                    id
                    firstName
                    lastName
                }
                watcherGroups {
                    id
                    name
                }
            }
        }
    }
`;

const UPDATE_ASSIGNERS_MUTATION = gql`
    mutation UpdateAssigners($input: UpdateJobNewInput!) {
        updateJobNew(input: $input) {
            error {
                type
                message
            }
            jobNew {
                id
                status
                statusChanges {
                    id
                    status
                    updater {
                        id
                        firstName
                        lastName
                    }
                    updatedAt
                }
                assigners {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

const MOVE_TO_NEXT_DATE_MUTATION = gql`
    mutation MoveToNextDate($input: UpdateJobNewInput!) {
        updateJobNew(input: $input) {
            error {
                type
                message
            }
            jobNew {
                id
                calculatedDate
                relationsApartmentClosestAvailable {
                    id
                    afterDate
                }
            }
        }
    }
`;

const DELETE_JOB_MUTATION = gql`
    mutation DeleteJob($input: DeleteJobNewInput!) {
        deleteJobNew(input: $input) {
            jobNew {
                id
                deleted
            }
            error {
                type
                message
            }
        }
    }
`;

const UNDELETE_JOB_MUTATION = gql`
    mutation UndeleteJob($input: UndeleteJobNewInput!) {
        undeleteJobNew(input: $input) {
            jobNew {
                id
                deleted
            }
            error {
                type
                message
            }
        }
    }
`;

export default function JobCard(props) {
    const {
        jobId
    } = props;

    const navigate = useNavigate();


    const { user, permissions } = useAuth();

    const { data, loading, error } = useQuery(QUERY, { variables: { jobId } });
    const [updateWatchers] = useMutation(UPDATE_WATCHERS_MUTATION);
    const [updateAssigners] = useMutation(UPDATE_ASSIGNERS_MUTATION);
    const [moveToNextDate] = useMutation(MOVE_TO_NEXT_DATE_MUTATION);
    const [deleteJob, { loading: deleteLoading }] = useMutation(DELETE_JOB_MUTATION, {
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'jobsNew',
            });
        },
    });
    const [undeleteJob, { loading: undeleteLoading }] = useMutation(UNDELETE_JOB_MUTATION, {
        update(cache) {
            cache.evict({
                id: 'ROOT_QUERY',
                fieldName: 'jobsNew',
            });
        },
    });

    function handleUpdateWatchers(watchers) {
        updateWatchers({
            variables: {
                input: {
                    jobNewId: jobId,
                    watcherIds: watchers
                        .filter(watcher => watcher.userId)
                        .map(watcher => watcher.userId),
                    watcherGroupIds: watchers
                        .filter(watcher => watcher.userGroupId)
                        .map(watcher => watcher.userGroupId),
                },
            },
        })
            .then(response => {
                if (response.data.updateJobNew.error) {
                    message.error("Failed to update watchers");
                }
                else {
                    message.success("Watchers updated");
                }
            })
            .catch(() => {
                message.error("Failed to update watchers");
            });
    }

    function handleUpdateAssigners(assignerIds) {
        updateAssigners({
            variables: {
                input: {
                    jobNewId: jobId,
                    assignerIds,
                },
            },
        })
            .then(response => {
                if (response.data.updateJobNew.error) {
                    message.error("Failed to update assigners");
                }
                else {
                    message.success("Assigners updated");
                }
            })
            .catch(() => {
                message.error("Failed to update assigners");
            });
    }

    function handleMoveToNextDate() {
        const relation = data.jobNew.relationsApartmentClosestAvailable[0];
        const afterDate = relation.afterDate
            ? max([data.jobNew.calculatedDate, addDays(relation.afterDate, 1)])
            : data.jobNew.calculatedDate;

        moveToNextDate({
            variables: {
                input: {
                    jobNewId: jobId,
                    relationsApartmentClosestAvailable: [{
                        apartmentId: data.jobNew.apartment.id,
                        afterDate,
                    }],
                },
            },
        })
            .then(response => {
                if (response.data.updateJobNew.error) {
                    message.error("Failed to move to next date");
                }
                else {
                    message.success("Moved to next date");
                }
            })
            .catch(() => {
                message.error("Failed to move to next date");
            });
    }

    function handleDelete() {
        deleteJob({
            variables: {
                input: {
                    jobNewId: jobId,
                },
            },
        })
            .then(response => {
                if (response.data.deleteJobNew.error) {
                    message.error("Failed to delete job");
                }
                else {
                    message.success("Job deleted");
                }
            })
            .catch(() => {
                message.error("Failed to delete job");
            });
    }

    function handleUndelete() {
        undeleteJob({
            variables: {
                input: {
                    jobNewId: jobId,
                },
            },
        })
            .then(response => {
                if (response.data.undeleteJobNew.error) {
                    message.error("Failed to undelete job");
                }
                else {
                    message.success("Job undeleted");
                }
            })
            .catch(() => {
                message.error("Failed to undelete job");
            });
    }

    if (loading) {
        return (
            <Card loading />
        )
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load job"
            />
        );
    }

    const userIsAssigner = data.jobNew.assigners.some(assigner => assigner.id === user.id);
    const userIsAuthor = data.jobNew.author.id === user.id;
    const isJobSelf = userIsAssigner || userIsAuthor;
    const canEdit = isJobSelf
        ? permissions.includes('job:update:self')
        : permissions.includes('job:update:other');

    const canMoveToNextDate = data.jobNew.relationsApartmentClosestAvailable.length > 0 && data.jobNew.status !== 'done';

    return (
        <Card
            title={
                <JobCardTitle
                    job={data.jobNew}
                    loading={loading}
                />
            }
            extra={
                <Space>
                    {canMoveToNextDate && canEdit && (
                        <Button onClick={() => handleMoveToNextDate()}>
                            Move to next date
                        </Button>
                    )}
                    <JobStatusDropdown
                        jobId={jobId}
                        editable={canEdit}
                    />
                </Space>
            }
        >
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Row gutter={[16, 16]}>
                        {data.jobNew.description && (
                            <Col span={24}>
                                <JobDescription
                                    description={data.jobNew.description}
                                />
                            </Col>
                        )}
                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <Row justify="end">
                                        <Col>
                                            Reservation
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={16}>
                                    <JobReservationSituation
                                        endingReservation={data.jobNew.endingReservation}
                                        currentReservation={data.jobNew.currentReservation}
                                        startingReservation={data.jobNew.startingReservation}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <Row justify="end">
                                        <Col>
                                            Watchers
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={16}>
                                    <JobWatchersList
                                        job={data.jobNew}
                                        editable={canEdit}
                                        onUpdate={watchers => handleUpdateWatchers(watchers)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <Row justify="end">
                                        <Col>
                                            Assigners
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={16}>
                                    <JobAssignersList
                                        job={data.jobNew}
                                        editable={canEdit}
                                        markSelf
                                        onUpdate={assignerIds => handleUpdateAssigners(assignerIds)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    {data.jobNew.attachments.length > 0 && (
                        <JobAttachmentsList
                            attachments={data.jobNew.attachments}
                        />
                    )}
                </Col>
                {canEdit && (
                    <Col span={24}>
                        <Row
                            gutter={[16, 16]}
                            justify="end"
                            align="bottom"
                        >
                            <Col>
                                {data.jobNew.deleted && (
                                    <Button
                                        icon={<DeleteOutlined />}
                                        onClick={() => handleUndelete()}
                                        loading={undeleteLoading}
                                    >
                                        Undelete
                                    </Button>
                                )}
                                {!data.jobNew.deleted && (
                                    <Button
                                        icon={<DeleteOutlined />}
                                        onClick={() => handleDelete()}
                                        loading={deleteLoading}
                                        danger
                                    >
                                        Delete
                                    </Button>
                                )}
                            </Col>
                            <Col>
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => navigate(`/jobs-new/${jobId}/edit`)}
                                >
                                    Edit
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        </Card>
    )
}