import { ThunderboltOutlined } from "@ant-design/icons";
import { Button, Form, Modal, PageHeader } from "antd";
import { useState } from "react";
import StayqlResultsTable from "stayql/components/stayql-results-table/StayqlResultsTable";
import QueryForm from "stayql/forms/QueryForm";

export default function GetStayqlResultsView() {
    const [form] = Form.useForm();

    const [query, setQuery] = useState();
    const [queryModalOpen, setQueryModalOpen] = useState(false);

    function handleRunQuery() {
        setQueryModalOpen(false);
        form
            .validateFields()
            .then(values => {
                setQuery(values.query);
            });
    }

    return (
        <PageHeader
            title="StayQL"
            extra={
                <Button
                    onClick={() => setQueryModalOpen(true)}
                    type="primary"
                    icon={<ThunderboltOutlined />}
                >
                    Query
                </Button>
            }
        >
            <StayqlResultsTable
                query={query}
            />
            <Modal
                open={queryModalOpen}
                title="Query"
                onOk={() => handleRunQuery()}
                onCancel={() => setQueryModalOpen(false)}
                width={700}
            >
                <QueryForm
                    form={form}
                />
            </Modal>
        </PageHeader>
    );
}